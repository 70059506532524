@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css';
@font-face {
    font-family: 'SIE';
    src: local('GoldmanBold'), url('../../public/fonts/SIE-T2.0.otf') format('truetype');
    font-display: swap;
}

.footer-wrapper {
    position: relative;

    width: 100%;
    margin-left: 150px;
    padding: 25px;

    z-index: 100;

    margin: 0px;

    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
            'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        font-size: 13pt;
    }

    p {
        margin: 0px;
    }
    h2 {
        margin-top: 7rem;
        margin-bottom: 4rem;
    }
    h3 {
        margin-top: 2rem;
    }
    p {
        margin: 0px;
    }
    h2 {
        margin-top: 7rem;
        margin-bottom: 4rem;
    }
    h3 {
        margin-top: 2rem;
    }
    .mx-auto {
        margin: auto;
    }
    .section > h1 {
        margin-top: 7rem;
    }

    /* Button */
    button {
        background-color: white;
        border: none;
        text-decoration: none;
        font-size: 13pt;
        padding: 8px 32px;
        border-radius: 5px;
    }
    button > a {
        text-decoration: none;
    }

    .developer, .platform { 
        max-width: 100vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;      

        font-weight: 900;
        
        div {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
    }
    .developer {
        margin-top: 50px;
    }
    .copyrights {
        text-align: center;
        line-height: 1.5;
    }
    .mailchimp {
        font-family: Arial, Helvetica, sans-serif;
    }
}

.le-buttons > button {
    margin: auto;
    margin: auto;
    width: 32%;
    color: white;
    /* display: inline-block; */
    background-color: rgb(8, 139, 214);
    text-decoration: none;
    border: none;
}
.le-buttons > button > a {
    color: white;
}

.de-buttons button {
    width: 26%;
    min-width: 180px;
    color: white;
    display: inline-block;
    background-color: rgb(155, 5, 185);
    text-decoration: none;
    border: none;
}

.de-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    /* margin: 2em auto; */
    max-width: 900px;
}

.de-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    /* margin: 2em auto; */
    max-width: 900px;
}
.de-buttons button a {
    color: white;
    text-decoration: none;
}

.digital-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin: auto;
    margin: auto;
    max-width: 900px;
}

.digital-buttons button {
    width: 26%;
    min-width: 180px;
    color: white;
    display: inline-block;
    background-color: rgb(155, 5, 185);
    text-decoration: none;
    border: none;
}
.digital-buttons button a {
    color: white;
    display: inline-block;
}

/* Product Page */

.platform {
    margin-bottom: 5rem;
}
.glamshot {
    width: auto;
    max-width: 100%;
}
.le-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin: 2em auto;
    max-width: 800px;
}

.de-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin: 1em auto;
    max-width: 900px;
}

.digital-section {
    margin-bottom: 8rem;
}
.fullscreen-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 5;
}
.block {
    display: block;
}
.hidden {
    display: hidden;
}
.fullscreen {
    /* position: relative; */
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
}
.fullscreen div {
    height: 100%;
    top: 0px;
    margin-top: 10rem;

    margin: auto;
    overflow: auto;
}

.enlarged-img {
    /* position: relative; */
}

.enlarged-img img {
    cursor: pointer;
    position: absolute;
    transform: translate(-50%);
    top: 50%;
    bottom: 50%;
    left: 50%;
    margin: auto;
    width: 80%;
    cursor: pointer;
    max-width: 560px;
}
.modal-close {
    position: absolute;
    top: 10px;
    right: 14px;
}
.rcc-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: space-around;
    max-width: 1200px;
    margin: auto;
}

.rcc-wrapper {
    margin-top: 4rem;
    max-width: 1000px;
}
.rcc-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    align-items: center;
}
.rcc-intro > .intro {
    flex-basis: 70%;
}
.rcc-intro img {
    max-width: 200px;
}
.rcc-wrapper p {
    max-width: 1000px;
    margin: auto;
    /* width: 100%; */
}

.rcc-card {
    width: auto;
}
@media (max-width: 760px) {
    .rcc-card {
        width: 48%;
    }
}

.rcc-card img {
    width: 80%;
    max-width: 260px;
    cursor: pointer;
}
@media (max-width: 760px) {
    .rcc-card img {
        width: 100%;
    }
}
.rcc-card-label {
    display: flex;
    flex-direction: column;
    margin: 8px 2px 12px 2px;
    justify-content: center;
}
.rcc-card-label label {
    display: inline-block;
    color: black;
}
.vote-wrapper {
    margin: 8rem 0rem;
}
.vote-wrapper > p {
    margin: 4rem 0rem;
}
.vote {
    display: flex;
    flex-direction: row;

    justify-content: center;
    gap: 2rem;
}
.vote > div {
}
.vote > p {
    padding-bottom: 2rem;
}
.btn-disabled {
    background-color: gray;
    color: rgb(197, 196, 196);
}
.rcc-resultcard-inner img {
    max-width: 120px;
}
.rcc-resultcard-inner > div > p {
    /* order: 2; */
    white-space: normal;
    margin-right: 1rem;
}
@media (max-width: 760px) {
    .rcc-resultcard-inner > div:nth-child(2) {
        order: 1;
    }
    .rcc-resultcard-inner > div > p {
        order: 2;
    }
    .rcc-resultcard-inner > div > img {
        order: 1;
    }
}
.rcc-results-wrapper {
    max-width: 800px;
    margin: 0px auto 8rem;
    /* padding: 0px 4rem; */
}

.rcc-resultbar {
    position: relative;
    height: 3em;
    background-color: black;
}
.rcc-resultbar p {
    color: white;
    position: absolute;
    right: 10px;
    font-size: 18pt;
    top: 50%;
    transform: translateY(-50%);
}

.rcc-results {
    display: flex;
    flex-direction: column;

    /* margin-top: 1rem; */
}
.rcc-resultcard {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    margin: 0rem auto 1.2rem;
}
.rcc-resultcard-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
}
@media (max-width: 760px) {
    .rcc-resultcard-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.rcc-results {
    display: flex;
    flex-direction: column;

    /* margin-top: 1rem; */
}
.rcc-resultcard {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    margin: 0rem auto 1.2rem;
}
.rcc-resultcard-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
}
@media (max-width: 760px) {
    .rcc-resultcard-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
/* Steam */
.steamcontainer {
    position: relative;
    padding-bottom: max(15px, 20.25%);
    height: 0;
    overflow: hidden;
    display: block;
    width: 60%;
    max-width: 800px;
}
@media (max-width: 1028px) {
    .steamcontainer {
        position: relative;
        padding-bottom: 28.25%;
        height: 0;
        overflow: hidden;
        display: block;
        width: 90%;
    }
}
@media (max-width: 760px) {
    .steamcontainer {
        position: relative;
        padding-bottom: 40.25%;
        padding-bottom: 40.25%;
        height: 0;
        overflow: hidden;
        display: block;
        width: 90%;
    }
}

.steamcontainer iframe,
.steamcontainer object,
.steamcontainer embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Footer */
footer {
    background-color: black;
    color: white;
    padding: 100px 20px;
}
@media (max-width: 760px) {
    footer {
        padding: 80px 10px;
    }
}
.font-sie {
    font-family: 'SIE';
   
}
.socials-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8vw;
    justify-content: center;
    align-items: center;
    padding: 4rem 0px;
    padding-bottom: 1rem;
    margin: auto;
}
@media (max-width: 760px) {
    .socials-wrapper {
        display: flex;
        flex-direction: column;
        padding: 2rem 1rem;
        gap: 2rem;
    }
}
.subscribe-wrapper {
    display: flex;
    margin: 2rem auto;
    justify-content: center;
    gap: 0.4rem;
}
.subscribe-wrapper input {
    min-width: 100px;
    width: 100%;
    /* border-radius: 10px; */
    padding: 0.4rem;
}
.mailchimp {
    flex-basis: content;
}
.socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    /* margin: auto; */
    flex-basis: content;
}
.socials i {
    color: white;
}
.platforms {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0px auto;
    justify-content: center;
    gap: 1rem;
}
.platforms > div {
    /* width: 100%; */
    height: 65px;
    /* max-width: 200px; */
    display: inline-block;
}
.platforms > div > img {
    height: 100%;
}
.developer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0px auto;
    /* max-width:600px; */
}
.developer > div {
    height: 90px;
    display: inline-block;
}
.developer > div > img {
    height: 100%;
}
.xbox {
    width: 40%;
}

.copyrights {
    padding-top: 2rem;
}
.esrb {
    border: solid white 1px;
}