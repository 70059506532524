.App {
  max-width: 100vw;
  min-height: 100vh;
  
  overflow: hidden;
  
  background-color: #442F1E;
}

.video-container {
  max-width: 1000px;
  width: 100%;    

  .video-wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
  
      iframe {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
      }
  }
}

.custom-bullet {
  width: 50px;
  height: 8px;
  background-color: white;
  border: solid 1px white;
  border-radius: 0px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  background: #442F1E; /* Active bullet color */
}

.custom-bullet.swiper-pagination-bullet{
  opacity: 1;
} 

.section-title {
  font-size: 3em;
  font-weight: 400;
  color: white;
}

.top-bar {
  background-image: url(../img/pattern-medium.webp);
  background-color: #442F1E;
  background-size: auto 100%;
  
  height: 80px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: solid 4px white;
}

.top-image {
  width: 300px;
}

.hero-slider {
  border-bottom: solid 4px white;
  padding: 0px !important;
  margin-bottom: 50px;
}

.hero-slide {
  position: relative;

  width: 100vw;
  max-width: 100vw;
  background-color: grey;

  display: flex !important;
  justify-content: center;
  align-items: center;

  a {
    position: relative;
    z-index: 1;
    max-height: auto;
  
    max-width: 1000px;
    width: 100vw;
  
    .hero-logo {
      position: absolute;
      top: 0;
      left: 0;
  
      max-width: 35%;
      width: 350px;
  
      filter: drop-shadow(0px 0px 10px rgba(white, 0.5));
    }
  }
}


.hero-image {
  z-index: 1;
  max-height: auto;

  max-width: 1000px;
  width: 100vw;
}

.hero-bg {
  position: absolute;
  width: 100%;

  filter: blur(10px);
}

.topics {
  display: flex;
  flex-direction: column;
  align-items: center;

  .swiper-button-next,
  .swiper-button-prev {
    color: #fff; /* Make the arrows white */
    background-color: rgba(0, 0, 0, 0.5); /* Optional: Add a semi-transparent background */
    border-radius: 50%; /* Optional: Make the buttons circular */
    padding: 10px;
    width: 40px;
    height: 40px;
  }

  .swiper-button-next {
    right: 2vw;
  }
  .swiper-button-prev {
    left: 2vw;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 18px; /* Optional: Adjust the size of the arrow icons */
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: rgba(255, 255, 255, 0.3); /* Optional: Change the background color on hover */
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    opacity: 0; /* Optional: Reduce opacity for disabled arrows */
  }
}

.topics .section-title {
  background-image: url(../img/pattern-dark.webp);
  background-color: #442F1E;
  background-size: auto 100%;
  
  width: 100%;
  height: 80px;
  background-color: #614039;

  display: flex;
  justify-content: center;
  align-items: center;
}

.topics-carousel {
  max-width: 100vw;
  padding-top: 50px;
  margin-bottom: 50px;



  a {
    text-decoration: none;
  }
  a:visited {
    color: white;
  }

  .swiper {    
    overflow: visible;
  }
  
}

.topic-slide {
  padding: 15px;
  background-color: #D3C2BB;
  width: 250px;
  height: 275px;

  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;

  border-radius: 10px;
}

.topic-image {
  width: 220px;
  height: 125px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    min-height: 125px;
  }
}

.topic-category {
  text-transform: uppercase;
  font-size: 1em;
  width: 80px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-bottom: 3px;

  color: white;

}

.topic-social {
  background-color: rgb(0, 119, 255)
}

.topic-store {
  background-color: #006d1b;
}

.topic-EVENT {
  background-color: orange;
}

.topic-TRAILER {
  background-color:  rgb(168, 44, 44);
}

.topics-details {
  width: 100%;
  margin-top: 10px;


  display: flex;
  justify-content: space-between;
}

.topics-date {
  color: #442F1E;
}

.topics-caption {
  font-size: 1.1em;
  font-weight: 700;

  text-align: center;

  margin-top: 15px;

  color: #442F1E;
}

.prologue-section {
  background-color: #614039;
  padding: 125px 5vw;

  background-image: url(../img/pattern-light.webp), url(../img/pattern-light.webp);
  background-position: top, bottom;
  background-size: 80px;
  background-repeat: repeat-x;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  color: white;

  line-height: 1.5em;

  .section-title {
    margin-bottom: 50px;
  }
  
  p {
    max-width: 1000px;

    font-size: 1.15em;
  }
}

.show-more {
  cursor: pointer;

  width: 200px;
  height: 50px;
  border-radius: 20px;
  background-color: rgb(0, 119, 255);

  font-size: 1.25em;
  font-family: 'Lato';
  color: white;
  border: 0px;

  margin: 20px;


  transition: 0.25s;
}

.show-more:hover {
  transform: scale(1.1);
}

.invisible {
  display: none;
}

.map-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #D3C2BB;

  .map {
    img {
      width: 100vw;
    }
  }
}

.map-locations {
  max-width: 1250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  padding: 20px;
}


.location-title {
  display: flex;
  justify-content: flex-start;
  align-items:  flex-start;

  width: 400px;

  margin: 10px 0px;

  color: #442F1E;

  line-height: 1.25em;

  .marker {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;

    width: 30px;
    height: 40px;

    background-image: url(../img/pin.png);
    background-size: 100% 100%;

    font-weight: 800;
    color: white;
  }

  .place {
    margin-left: 10px;
  }

  .location-main {
    font-size: 1.15em;
    font-weight: 800;
  }

  .location-game {
    color: #0082C3;
    font-style: italic;
  }
}

.store-section {
  background-color: #442F1E;
  padding: 125px 0px 50px;

  background-image: url(../img/pattern-light.webp);
  background-position: top;
  background-size: 80px;
  background-repeat: repeat-x;

  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.store-carousel {
  max-width: 100vw;
  padding: 50px 0px;

  .swiper-button-prev {
    left: 2vw;
  }
  .swiper-button-next {
    right: 2vw;
  }
}

.store-item {
  max-width: 90vw;
  width: 500px;
  min-width: 300px;

  height: 420px;

  display: flex;
  flex-direction: column;
  align-items: center;


  .store-image {
    width: 90%;
    background-color: white;
    img {
      width: 100%;
    }
  }

  .store-item_title {
    max-width: 400px;
    text-align: center;
    font-size: 1.25em;    
    font-weight: 600;
    color: white;
    margin: 10px 0px;
  }
}

.store-button {
  background-color: #D3C2BB;
  min-width: 180px;
  height: 50px;
  padding: 10px;

  background-image: url(../img/pattern-light.webp);
  background-position: top;
  background-size: 50px;
  background-repeat: repeat-x;
  
  border: 0px;
  border-radius: 10px;

  color: #442F1E;
  font-size: 1.15em;
  font-weight: 500;

  cursor: pointer;
}

.official-section {
  background-color: #D3C2BB;
  padding: 125px 0px 5px;

  background-image: url(../img/pattern-dark.webp);
  background-position: top;
  background-size: 80px;
  background-repeat: repeat-x;

  text-align: center;
  
  .section-title {
    color: #442F1E;
  }
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(black, 0.8);

  z-index: 5;
  

  display: flex;
  justify-content: center;
  align-items: center;

  visibility: hidden;
  pointer-events: none;
}

.modal-close {
  position: absolute;

  top: 0;
  right: 0;
  margin: 40px 50px;

  background-color: white;
  border: solid white 2px;
  border: 5px;

  font-size: 2em;
  transform: scaleX(1.5);

  cursor: pointer;
}

.modal-visible {  
  visibility: visible;
  pointer-events: all;
}

.portal-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  padding: 50px 0px;  
  margin: 25px 0px;
  background-position: center;

  button {
    background-color: #D3C2BB;
    min-width: 180px;
    height: 50px;
    padding: 10px;
  
    background-image: url(../img/pattern-light.webp);
    background-position: top;
    background-size: 50px;
    background-repeat: repeat-x;
    
    border: 0px;
    border-radius: 10px;
  
    color: #442F1E;
    font-size: 1.15em;
    font-weight: 500;

    margin-bottom: 15px;

    cursor: pointer;

    transition: 0.25s;
  }
  button:hover {
    transform: scale(1.1);
  }
}

.portal-logo {
  max-width: 400px;

}

.portal-contents {
  display: flex;
  flex-direction: column;
  align-items: center;

  filter: drop-shadow(0px 0px 4px rgba(black, 0.5));

  .trailer-button {
    height: auto;
    background-color: transparent !important;
    background-image: none !important;

    padding: 0px;
    max-width: 450px;
    width: 90vw;

    cursor: pointer;

    img {
      width: 100%;
    }

    transition: 0.25s
  }
  .trailer-button:hover {
    filter: brightness(1.25);
  }
}

.ys-x-bg {
  background-image: url(../img/ys-x.webp);
  background-size: cover;

  margin-bottom: 0px;
  
  .website-button {
    background-color: #001F4D;
    background-blend-mode: soft-light;
    background-size: auto 100%;
    color: white;
    
    font-weight: 600;
  }
  .product-button {
    background-color: #B9B9B9;
    background-blend-mode: soft-light;
    background-size: auto 100%;
    color: #442F1E;
    
    font-weight: 600;
  }
}
.ys-ix-bg {
  background-image: url(../img/ys-ix.webp);
  background-size: cover;

  .website-button {
    background-color: #4B0101;
    background-blend-mode: soft-light;
    background-size: auto 100%;
    color: white;
    
    font-weight: 600;
  }
  .product-button {
    background-color: white;
    background-blend-mode: darken;
    background-size: auto 100%;
    color: black;
    
    font-weight: 600;
  }
}
.ys-viii-bg {
  background-image: url(../img/ys-viii.jpg);
  background-size: cover;
  margin-bottom: 20px;

  .website-button {
    background-color: #0D22AE;
    background-blend-mode: soft-light;
    background-size: auto 100%;
    color: white;
    
    font-weight: 600;
  }
  .product-button {
    background-color: #95FFC9;
    background-blend-mode: lighten;
    background-size: auto 100%;
    color: #042828;
    
    font-weight: 600;
  }
}

.ys-x_about {
  background-image: linear-gradient(90deg,#002836 10%, rgba(#002836, 0)), url(../img/visual.webp);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 5vw;
}

.ys-x_caption {
  background-color: #442F1E;
  color: white;
  padding: 10px;
  font-weight: 800;

  margin-bottom: 20px;
}

.ys-x_title {
  font-size: 2em;
  color: white;
  font-weight: 800;
  
  margin-bottom: 20px;
}

.ys-x_body {
  text-align: left;
  color: white;
  font-weight: 600;
  line-height: 1.5em;

  max-width: 800px;
}

.ys-x_date {
  text-align: left;
  color: #DA6A38;
  font-size: 1.5em;
  font-weight: 800;

  margin-bottom: 20px;
}

